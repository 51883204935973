import { useContext, useState, useEffect } from "react";
// CSS
import "./CartModal.css";
// Helpers
import { NavLink } from "react-router-dom";
import { MerchContext } from "context/merchContext";
import useData from "../../context/dataContext";
import axiosInstance from "axiosHelper/axios";
// Assets
import powMoney from "../../images/powMoney.svg";
import punksMoney from "../../images/punksMoney.svg";
import HoodiePink from "../../images/HoodiePink.png";
import checkMark from "../../images/checkMark.svg";
import { truncateAddress } from "utils/utils";

type CartProp = {
  open: boolean;
};

const CartModal: React.FC<CartProp> = (props: CartProp) => {
  const {
    cartItems,
    setCartItems,
    setCartAmount,
    cartAmount,
    setMissingShippingOrders,
    setMissingShippingOrdersDets,
    missingShippingOrdersDets,
  } = useContext(MerchContext);
  const { account } = useData();
  const [isMissingStep, setIsMissingStep] = useState(false);
  // const [retry, setRetry] = useState(false);
  const { open } = props;
  // const [retry, setRetry] = useState<boolean>(false);

  useEffect(() => {
    if (account && account !== undefined) {
      checkOrder(account);
      // setRetry(false);
    }
  }, [account]);

  let itemsPunksSum = 0;
  let itemsPowSum = 0;

  for (let i = 0; i < cartItems.length; i++) {
    itemsPunksSum += parseFloat(cartItems[i].price_punks);
    itemsPowSum += parseFloat(cartItems[i].price_pow);
  }

  const checkOrder = async (account_to_check: string) => {
    await axiosInstance.get(`/account/check-order/${account_to_check}`).then((res: any) => {
      console.log("CHECKING FOR PENDING ORDERS: ", account_to_check, " DATA:", res.data);
      if (res.data.shipping_paid === false) {
        // console.log(res.data.unpaid_orders[0]?.["items"]);
        setMissingShippingOrders(res.data.unpaid_orders[0]?.["items"]);
        setMissingShippingOrdersDets(res.data.unpaid_orders[0]);
        setIsMissingStep(true);
      }
      // else {
      //   setIsMissingStep(false);
      // }
    });
  };

  const handleCartItemRemove = (idx: any) => {
    let newArr = cartItems.filter((item: any) => {
      return item !== idx;
    });

    if (cartAmount === 0) {
      setCartAmount(0);
    } else {
      setCartAmount(cartAmount - 1);
    }

    setCartItems(newArr);
    window.localStorage.setItem("Cart_Items", JSON.stringify(newArr));
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={open ? "cart-modal opened-cart-modal" : "cart-modal"}>
      {!isMissingStep ? (
        <>
          <div className="top-cart-cont">
            <div className="top-cart-heading">
              <h2>Your Bag</h2>
              <span>
                <>{cartAmount > 0 ? null : "There are no items in your bag."}</>
              </span>
            </div>
            <div className="top-cart-items">
              {cartItems.map((item: any) => (
                <div key={item.item_id} className="cart-item-cont">
                  <img src={item.item_img} alt="item" />
                  <div className="single-item-info">
                    <h4>{item.item_name}</h4>
                    <div className="single-item-price">
                      <img src={punksMoney} alt="punks" />
                      <span>{item.price_punks.toLocaleString("en-US")}</span>
                      <img src={powMoney} alt="pow" />
                      <span>{item.price_pow.toLocaleString("en-US")}</span>
                      <div color="white">Size: {item.size_choice}</div>
                    </div>
                  </div>
                  <div className="x-remove" onClick={() => handleCartItemRemove(item)}>
                    x
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="bottom-cart-cont">
            <div className="bottom-cart-info">
              <span>Total:</span>
              <span>
                <img src={punksMoney} alt="punks" /> {itemsPunksSum.toLocaleString("en-US")} punks &#8195;
                <span className="or-text">or</span>&#8195;
                <img src={powMoney} alt="pow" /> {itemsPowSum.toLocaleString("en-US")} pow
              </span>
            </div>
            <NavLink className="checkout-btn" onClick={handleClick} to="/shop-checkout">
              Checkout
            </NavLink>
          </div>
        </>
      ) : (
        <>
          <div className="top-cart-cont">
            <div className="top-cart-heading">
              <h2>Complete Your Order</h2>
              <span style={{ color: "#FFB800" }}>
                We are missing some information to process your order! Please see below.
              </span>
            </div>
            <div className="top-cart-items">
              <div className="cart-item-cont">
                <img style={{ height: "24px", marginRight: "10px" }} src={checkMark} alt="item" />
                <div className="single-item-info">
                  <h4 style={{ marginBottom: "0px" }}>Connect Your Wallet</h4>
                  <div className="single-item-price">
                    <span>{truncateAddress(account)}</span>
                  </div>
                </div>
              </div>
              <div className="cart-item-cont">
                <img style={{ height: "24px", marginRight: "10px" }} src={checkMark} alt="item" />
                <div className="single-item-info">
                  <h4 style={{ marginBottom: "0px" }}>Order Payment</h4>
                  <div className="single-item-price">
                    <span>
                      {missingShippingOrdersDets?.total_price.toLocaleString("en-US")} $
                      {missingShippingOrdersDets?.price_choice.toLocaleUpperCase()}
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{ background: "transparent", border: "1px solid rgba(112, 112, 124, 0.2)" }}
                className="cart-item-cont"
              >
                <img style={{ height: "24px", marginRight: "10px", opacity: "0.2" }} src={checkMark} alt="item" />
                <div className="single-item-info">
                  <h4 style={{ marginBottom: "0px", color: "grey" }}>Shipping Payment</h4>
                  <div className="single-item-price">
                    <span style={{ color: "grey" }}>Incomplete</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-cart-cont">
            <NavLink className="checkout-btn" onClick={handleClick} to="/shop-checkout">
              Pay Shipping
            </NavLink>
            <span>Proceed to shipping.</span>
          </div>
        </>
      )}
    </div>
  );
};

export default CartModal;
