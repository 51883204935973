import { ReactElement, useEffect, useState } from "react";
// Components
import AlternateFooterSection from "components/FooterSection/AlternateFooterSection";
import AlternateNavbar from "components/Navbar/AlternateNavbar";
// Assets
import punksMoney from "images/punksMoney.svg";
import powMoney from "images/powMoney.svg";
import MH_HAT_FRONT from "images/MH-HAT-FRONT.png";
import MH_SHIRT_FRONT from "images/MH-SHIRT-FRONT.png";
import PUNKS_HAT_FRONT from "images/PUNKS-HAT-FRONT.png";
import PUNKS_SHIRT_FRONT from "images/PUNKS-SHIRT-FRONT.png";
import PV_HOODIE_FRONT from "images/PV-HOODIE-FRONT.png";

// Helpers
import useData from "../../../context/dataContext";
import axiosInstance from "axiosHelper/axios";
// CSS
import "../MerchPage.css";
import "./MerchSuccess.css";

interface OrderDetails {
  status: string;
  address: string;
  items: any[];
  total_price: number;
  price_choice: string;
}

const MerchSuccess = (): ReactElement => {
  const { account } = useData();
  const [orderDetails, setOrderDetails] = useState<OrderDetails>();
  const [orderID, setOrderID] = useState<string>();

  useEffect(() => {
    if (window.location.pathname.indexOf("/success") > -1) {
      const appEL = document.querySelector<HTMLElement>(".App");
      appEL!.style.backgroundColor = "#000000";
      setOrderID(window.location.pathname.split("/").pop());
      // getOrderDetails();
    }
  }, []);

  const merchImages = [
    { imgSrc: PV_HOODIE_FRONT },
    { imgSrc: PUNKS_HAT_FRONT },
    { imgSrc: PUNKS_SHIRT_FRONT },
    { imgSrc: MH_HAT_FRONT },
    { imgSrc: MH_SHIRT_FRONT },
  ];

  useEffect(() => {
    if (account || orderID) {
      getOrderDetails();
    }
  }, [account, orderID]);

  const getOrderDetails = async () => {
    await axiosInstance
      .get(`/order/${orderID}`)
      .then(res => {
        const orderData = res.data.order;
        setOrderDetails(orderData);
      })
      .catch(err => {
        console.log("ERROR: ", err);
      });
  };

  return (
    <>
      <AlternateNavbar />
      <div className="merch-banner">
      </div>
      <main style={{ marginBottom: "20%" }} className="merch-page">
        {/* Landing  */}
        <div className="checkout-success-layout">
          <div className="success-title">You're all set!</div>
          <span className="success-desc">
            You will receive an order confirmation email shortly. For questions, please email support@pixelvault.com
          </span>
          <div className="success-order-summary">
            <div className="success-order-summary-title">Order Summary</div>

            <hr className="horz-line" />
            <div className="success-order-summary-row">
              <div className="success-order-summary-subtitle">Items</div>
              <div className="success-order-items-grid">
                {orderDetails?.items.map(item => (
                  <div key={item.item_id} className="cart-item-cont">
                    <img src={merchImages[item.item_id].imgSrc} alt="item" />
                    <div className="single-item-info">
                      <h4>{item.name}</h4>
                      <div className="single-item-price">
                        <img style={{ height: "16px" }} src={punksMoney} alt="punks" />
                        <span>{item.price_punks.toLocaleString("en-US")}</span>
                        <img style={{ height: "16px" }} src={powMoney} alt="pow" />
                        <span>{item.price_pow.toLocaleString("en-US")}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="success-order-summary-row">
              <div className="success-order-summary-subtitle">Wallet</div>
              <div className="success-order-info">{orderDetails?.address}</div>
            </div>
            <div className="success-order-summary-row">
              <div className="success-order-summary-subtitle">Order Payment</div>
              <div className="success-order-info">
                {orderDetails?.total_price.toLocaleString("en-US")} ${orderDetails?.price_choice.toLocaleUpperCase()}
              </div>
            </div>
          </div>
        </div>
      </main>
      <AlternateFooterSection />
    </>
  );
};

export default MerchSuccess;
