import { ReactElement, useEffect, useState, useContext } from "react";
// Components
import AlternateFooterSection from "components/FooterSection/AlternateFooterSection";
import DescriptionSection from "components/DescriptionSection/DescriptionSection";
import Section from "components/Section/Section";
import MerchCard from "components/MerchCard/MerchCard";
import AlternateNavbar from "components/Navbar/AlternateNavbar";
// Helpers
import { MerchContext } from "context/merchContext";
// Assets
import PUNKS_MH_HAT_BACK from "images/PV-MH-HAT-BACK.png";
import MH_HAT_FRONT from "images/MH-HAT-FRONT.png";
import MH_HAT_SIDE from "images/MH-HAT-SIDE.png";
import MH_SHIRT_FRONT from "images/MH-SHIRT-FRONT.png";
import MH_SHIRT_BACK from "images/MH-SHIRT-BACK.png";
import PUNKS_HAT_FRONT from "images/PUNKS-HAT-FRONT.png";
import PUNKS_HAT_SIDE from "images/PUNKS-HAT-SIDE.png";
import PUNKS_SHIRT_FRONT from "images/PUNKS-SHIRT-FRONT.png";
import PUNKS_SHIRT_BACK from "images/PUNKS-SHIRT-BACK.png";
import PV_HOODIE_FRONT from "images/PV-HOODIE-FRONT.png";
import PV_HOODIE_BACK from "images/PV-HOODIE-BACK.png";
import PV_HOODIE_DETAIL_BACK from "images/PV-HOODIE-DETAIL-BACK.png";
import PV_HOODIE_FRONT_DETAIL from "images/PV-HOODIE-FRONT-DETAIL.png";
import PV_MERCH_MAIN_GIF from "images/Merch-Store-GIF-Animation---Transparent (1).gif";

// CSS
import "./MerchPage.css";

const MerchPage = (): ReactElement => {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openImageIdx, setOpenImageIdx] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { itemsInfo } = useContext(MerchContext);

  // Close the shop switch
  let shopClosed = true;

  useEffect(() => {
    if (window.location.pathname === "/") {
      const appEL = document.querySelector<HTMLElement>(".App");
      appEL!.style.backgroundColor = "#000000";
    }
  }, []);

  useEffect(() => {
    if (itemsInfo.length > 0) {
      setIsLoading(false);
    }
  }, [itemsInfo]);

  const sizeArr = ["S", "M", "L", "XL", "2XL", "3XL"];

  const dummyMerch = [
    {
      id: 0,
      imgArr: [
        { imgSrc: PV_HOODIE_FRONT },
        { imgSrc: PV_HOODIE_BACK },
        { imgSrc: PV_HOODIE_DETAIL_BACK },
        { imgSrc: PV_HOODIE_FRONT_DETAIL },
      ],
      title: "Pullover Hoodie",
      smallTitle: "Pixel Vault",
      sizes: sizeArr.slice(0, itemsInfo[0]?.["amount_of_sizes"]),
      details:
        "Letterman-style Pixel Vault chenille and felt patch on left chest and rear embroidered Pixel Vault logo. Final product may differ slightly.",
      material: "80% Cotton 20% Polyester",
      fit: "Unisex",
      color: "Black",
      availability: "Available for preorder",
      availabilitySub: "Estimated ship date in October",
      claimed: itemsInfo[0]?.["is_claimed"],
    },
    {
      id: 1,
      imgArr: [{ imgSrc: PUNKS_HAT_FRONT }, { imgSrc: PUNKS_HAT_SIDE }, { imgSrc: PUNKS_MH_HAT_BACK }],
      title: "Dad Hat",
      smallTitle: "PUNKS",
      sizes: sizeArr.slice(0, itemsInfo[1]?.["amount_of_sizes"]),
      details:
        "PUNKS unstructured snapback with embroidered icon at front and embroidered logo at side. Final product may differ slightly.",
      material: "100% Cotton Twill",
      fit: "Dad hat with snapback",
      color: "Black",
      availability: "Available for preorder",
      availabilitySub: "Estimated ship date in October",
      claimed: itemsInfo[1]?.["is_claimed"],
    },
    {
      id: 2,
      imgArr: [{ imgSrc: PUNKS_SHIRT_FRONT }, { imgSrc: PUNKS_SHIRT_BACK }],
      title: "Illustrated T-Shirt",
      smallTitle: "PUNKS",
      sizes: sizeArr.slice(0, itemsInfo[2]?.["amount_of_sizes"]),
      details:
        "PUNKS logo on left chest and exclusive Chris Wahl illustration at rear. Final product may differ slightly.",
      material: "100% Combed Ringspun Cotton",
      fit: "Unisex",
      color: "Black",
      availability: "Available for preorder",
      availabilitySub: "Estimated ship date in October",
      claimed: itemsInfo[2]?.["is_claimed"],
    },
    {
      id: 3,
      imgArr: [{ imgSrc: MH_HAT_FRONT }, { imgSrc: MH_HAT_SIDE }, { imgSrc: PUNKS_MH_HAT_BACK }],
      title: "Dad Hat",
      smallTitle: "PV Classics: MetaHero",
      sizes: sizeArr.slice(0, itemsInfo[3]?.["amount_of_sizes"]),
      details:
        "Classic MetaHero Universe unstructured snapback with embroidered icon patch at front and embroidered logo at side. Final product may differ slightly.",
      material: "100% Cotton Twill",
      fit: "Dad hat with snapback",
      color: "Black",
      availability: "Available for preorder",
      availabilitySub: "Estimated ship date in October",
      claimed: itemsInfo[3]?.["is_claimed"],
    },
    {
      id: 4,
      imgArr: [{ imgSrc: MH_SHIRT_FRONT }, { imgSrc: MH_SHIRT_BACK }],
      title: "Illustrated T-Shirt",
      smallTitle: "PV Classics: MetaHero",
      sizes: sizeArr.slice(0, itemsInfo[4]?.["amount_of_sizes"]),
      details:
        "Classic MetaHero Universe logo on center chest and exclusive Chris Wahl illustration at rear. Final product may differ slightly.",
      material: "100% Cotton",
      fit: "Unisex",
      color: "Black",
      availability: "Available for preorder",
      availabilitySub: "Estimated ship date in October",
      claimed: itemsInfo[4]?.["is_claimed"],
    },
  ];

  return (
    <>
      <AlternateNavbar />
      <div className="merch-banner"></div>
      <main className="merch-page">
        {!shopClosed ? (
          <>
            {openImageModal ? (
              <div className="image-modal">
                <div className="close-image-modal">
                  <div className="x-close-image" onClick={() => setOpenImageModal(false)}>
                    Close
                    <div></div>
                  </div>
                </div>
                <div className="image-modal-container">
                  <div className="image-container">
                    <img
                      className="image-modal-image"
                      src={dummyMerch[openImageIdx]?.imgArr[0].imgSrc}
                      alt="clothing product"
                    />
                  </div>
                  <div className="opened-img-details">
                    <span className="opened-img-title">
                      {dummyMerch[openImageIdx]?.smallTitle} {dummyMerch[openImageIdx]?.title}
                    </span>
                    <span className="opened-img-desc">{dummyMerch[openImageIdx]?.details}</span>
                    <hr className="horz-line" />
                    <span className="opened-img-subtitle">Traits</span>
                    <div className="opened-img-info-grid">
                      <div className="info-grid-dets">
                        <span className="info-grid-title">Material</span>
                        <span className="info-grid-detail">{dummyMerch[openImageIdx]?.material}</span>
                      </div>
                      <div className="info-grid-dets">
                        <span className="info-grid-title">Fit</span>
                        <span className="info-grid-detail">{dummyMerch[openImageIdx]?.fit}</span>
                      </div>
                      <div className="info-grid-dets">
                        <span className="info-grid-title">Color</span>
                        <span className="info-grid-detail">{dummyMerch[openImageIdx]?.color}</span>
                      </div>
                      <div className="info-grid-dets">
                        <span className="info-grid-title">Availability</span>
                        <span className="info-grid-detail">{dummyMerch[openImageIdx]?.availability}</span>
                        <span className="info-grid-detail2">{dummyMerch[openImageIdx]?.availabilitySub}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <DescriptionSection
              title="MERCH ISSUE #1"
              content="PV, PUNKS and MetaHero, oh my! Wear your favorite NFTs on your sleeve with Pixel Vault’s first-ever merchandise collection. Available to shop for 48 hours only, or while supplies last."
              buttonText="Shop Now"
              buttonClassName="merch-btn"
              buttonHandler={() => window.scrollTo(0, 700)}
              image={PV_MERCH_MAIN_GIF}
              imgClassName="merch-item"
              alt="merch item"
              size="normal"
            />
            {/* Items Grid */}
            <Section>
              <div id="shop-grid" className="merch-grid">
                {dummyMerch.map((item, index) => (
                  <MerchCard
                    key={index}
                    itemIdx={index}
                    openImg={setOpenImageModal}
                    openImgIdx={setOpenImageIdx}
                    thisItem={item}
                    id={item.id}
                    imgArr={item.imgArr}
                    title={item.title}
                    smallTitle={item.smallTitle}
                    pricePunks={!isLoading ? itemsInfo[item.id]?.["cost_of_in_punks"] : "-"}
                    pricePOW={!isLoading ? itemsInfo[item.id]?.["cost_of_in_pow"] : "-"}
                    sizes={item.sizes}
                    isClaimed={item.claimed}
                  />
                ))}
              </div>
            </Section>
          </>
        ) : (
          <>
            <Section>
              <>
                <div className="shop-closed-cont">
                  {/* <span className="shop-closed-smtitle">REOPENING JANUARY 2023</span> */}
                  <div className="shop-closed-title">SHOP CLOSED</div>
                  {/* <span className="shop-closed-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</span> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    // marginTop: "5rem",
                  }}
                >
                  {/* <span className="shop-closed-smtitle">PREVIOUS DROP</span> */}
                  <div className="main-skills-reel">
                    <div className="skills-reel" id="skill-reel">
                      <img style={{ height: "386px" }} className="reel" src={PUNKS_HAT_FRONT} alt="merch item" />
                      <img style={{ height: "386px" }} className="reel" src={PUNKS_SHIRT_FRONT} alt="merch item" />
                      <img style={{ height: "386px" }} className="reel" src={MH_HAT_FRONT} alt="merch item" />
                      <img style={{ height: "386px" }} className="reel" src={MH_SHIRT_FRONT} alt="merch item" />
                      <img style={{ height: "386px" }} className="reel" src={PV_HOODIE_FRONT} alt="merch item" />
                    </div>
                  </div>
                </div>
              </>
            </Section>
          </>
        )}
      </main>
      <AlternateFooterSection />
    </>
  );
};

export default MerchPage;
