import { ReactElement, useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
// Components
import AlternateFooterSection from "components/FooterSection/AlternateFooterSection";
import AlternateNavbar from "components/Navbar/AlternateNavbar";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
// Assets
import powMoney from "../../../images/powMoney.svg";
import punksMoney from "../../../images/punksMoney.svg";
import editPencil from "images/editPencil.svg";
import checkMark from "../../../images/checkMark.svg";
import MH_HAT_FRONT from "images/MH-HAT-FRONT.png";
import MH_SHIRT_FRONT from "images/MH-SHIRT-FRONT.png";
import PUNKS_HAT_FRONT from "images/PUNKS-HAT-FRONT.png";
import PUNKS_SHIRT_FRONT from "images/PUNKS-SHIRT-FRONT.png";
import PV_HOODIE_FRONT from "images/PV-HOODIE-FRONT.png";
// Helpers
import { truncateAddress } from "utils/utils";
import useData from "../../../context/dataContext";
import { MerchContext } from "context/merchContext";
import axiosInstance from "axiosHelper/axios";
import { Contract } from "ethers";
import { TransactionResponse } from "@ethersproject/abstract-provider";
import { ethers } from "ethers";
// CSS
import "../MerchPage.css";
import "./MerchCheckout.css";

interface TransactionResult {
  code: number;
  message: string;
  stack: string;
  hash: string;
  status: number | string;
}

const MerchCheckout = (): ReactElement => {
  const { account } = useData();
  const {
    cartItems,
    setCartItems,
    setCartAmount,
    cartAmount,
    powBalance,
    punksBalance,
    powContract,
    punksContract,
    merchContract,
    missingShippingOrders,
    setPowBalance,
    setPunksBalance,
    missingShippingOrdersDets,
    setDoRefresh,
  } = useContext(MerchContext);
  const [activeStep, setActiveStep] = useState("walletStep");
  const [checkedWallet, setCheckedWallet] = useState(false);
  const [checkedPayment, setCheckedPayment] = useState<boolean>();
  const [checkedCountry, setCheckedCountry] = useState<boolean>();
  // const [checkedShipping, setCheckedShipping] = useState<boolean>();
  const [paymentOption, setPaymentOption] = useState<string>();
  const [punksApproved, setPunksApproved] = useState<boolean>(false);
  const [powApproved, setPowApproved] = useState<boolean>(false);
  const [countries, setCountries] = useState<{ code: string; name: string }[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("Select your country");
  const [displayCountry, setDisplayCountry] = useState<string>();
  const [stripeURL, setStripeURL] = useState<string>();
  const [shippingCost, setShippingCost] = useState<number>();
  const [paymentAmt, setPaymentAmt] = useState<number>();

  //error handling
  const [transactionError, setTransactionError] = useState<string>("");
  const [approvalError, setApprovalError] = useState<string>("");
  const [transactionPayState, setTransactionPayState] = useState<TransactionResult>();
  const [stripeURLError, setStripeURLError] = useState<boolean>(false);
  const [notEnoughPunks, setNotEnoughPunks] = useState<boolean>(false);
  const [notEnoughPow, setNotEnoughPow] = useState<boolean>(false);

  //wallet intructions for user
  const [walletInstructions, setWalletInstructions] = useState<boolean>(false);

  //waiting for approval loading state
  const [approvalLoading, setApprovalLoading] = useState<boolean>(false);
  const [processLoading, setProcessLoading] = useState<boolean>(false);

  const [backToShop, setBackToShop] = useState<boolean>(false);

  const windowWidth = window.innerWidth;

  useEffect(() => {
    if (window.location.pathname === "/shop-checkout") {
      const appEL = document.querySelector<HTMLElement>(".App");
      appEL!.style.backgroundColor = "#000000";
    }
  }, []);

  useEffect(() => {
    setNotEnoughPunks(itemsPunksSum <= punksBalance ? false : true);
    setNotEnoughPow(itemsPowSum <= powBalance ? false : true);
    // console.log("NOT CART HERE", itemsPowSum, " punks ==> ", itemsPunksSum);
  }, [powBalance, punksBalance, cartAmount]);

  useEffect(() => {
    checkTokenAllowance(true); //checks pow allowance
    checkTokenAllowance(false); //checks punks allowance
    if (account) {
      if (missingShippingOrders.length > 0) {
        console.log("BRAND NEW CONSOLE: ", missingShippingOrdersDets.country);
        createNewSession(missingShippingOrdersDets.country);
        setActiveStep("shippingStep");
        setCheckedPayment(true);
        setCheckedCountry(true);
        setCheckedWallet(true);
        getShippingEstimate(missingShippingOrdersDets?.country);
      } else {
        setCheckedWallet(true);
        setActiveStep("countryStep");
      }
      console.log("COUNTRY SET HERES: ", selectedCountry);
    }
  }, [account, missingShippingOrders]);

  useEffect(() => {
    const getCountries = async () => {
      await axiosInstance
        .get("/shipping/countries")
        .then(res => {
          const countriesData = res.data;
          const sortCountries = countriesData.sort((a: any, b: any) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          setCountries(sortCountries);
          if (missingShippingOrders.length > 0) {
            const searchIndex = countries.findIndex(
              (country: any) => country.code == missingShippingOrdersDets.country
            );
            setDisplayCountry(countries[searchIndex].name);
          }
        })
        .catch(err => {
          console.log("ERROR: ", err);
        });
    };
    getCountries();
  }, []);

  useEffect(() => {
    getShippingEstimate(selectedCountry);
  }, [cartItems, cartItems.length]);

  useEffect(() => {
    let count_code = missingShippingOrdersDets?.country;
    if (missingShippingOrders.length > 0 && count_code !== undefined) {
      const searchIndex = countries.findIndex((country: any) => country.code == missingShippingOrdersDets.country);
      console.log("SEARCH INDEX FOR COUNTRY: ", searchIndex);
      if (searchIndex !== -1) {
        setDisplayCountry(countries[searchIndex].name);
      }
    }
  }, [missingShippingOrders]);

  const handleCountrySelect = (country_name: any) => {
    const getCountryName = country_name;
    setSelectedCountry(getCountryName);
  };

  const handleNextStep = (stepsName: string) => {
    setActiveStep(stepsName);
  };

  let itemsPunksSum = 0;
  let itemsPowSum = 0;

  for (let i = 0; i < cartItems.length; i++) {
    itemsPunksSum += Number.parseFloat(cartItems[i].price_punks);
    itemsPowSum += Number.parseFloat(cartItems[i].price_pow);
  }

  const createNewSession = async (sending_to?: string) => {
    console.log("MISSING SHIPPING ORDERS: ", missingShippingOrders);

    // const getSizeChoice = () => {
    for (let i = 0; i < missingShippingOrders.length; i++) {
      missingShippingOrders[i].size_choice = missingShippingOrders[i]["size"];
      // delete missingShippingOrders[i].size;
    }
    // }

    let detailsObj = {
      order_summary: missingShippingOrders,
      account: account,
      account_id: "",
      total_items: missingShippingOrders.length,
      total_price: missingShippingOrdersDets?.total_price,
      price_choice: missingShippingOrdersDets?.price_choice,
      country: sending_to ? sending_to : missingShippingOrdersDets?.country,
    };

    console.log("DETAILS from create new sess: ", detailsObj);

    const res = await axiosInstance
      .post("/stripe/create-session", JSON.stringify(detailsObj))
      .then((res: any) => {
        setStripeURL(res.data.url);
        setStripeURLError(false);
        console.log("SET COUNTRY TO STATE: ", missingShippingOrdersDets?.country, " state: ", selectedCountry);
        console.log("NEW SESSION DATA: ", res.data);
        return res.data;
      })
      .catch((err: any) => {
        console.error(err);
        setStripeURLError(true);
      });
    return res;
  };

  const userItemsDetails = async (totalPrice: number, priceIn: string) => {
    let detailsObj = {
      order_summary: cartItems,
      account: account,
      account_id: "",
      total_items: cartItems.length,
      total_price: totalPrice,
      price_choice: priceIn,
      country: selectedCountry,
    };

    const res = await axiosInstance
      .post("/stripe/create-session", JSON.stringify(detailsObj))
      .then((res: any) => {
        setStripeURL(res.data.url);
        setStripeURLError(false);
        return res.data;
      })
      .catch((err: any) => {
        console.error(err);
        setStripeURLError(true);
      });
    return res;
  };

  const getShippingEstimate = async (send_to: string) => {
    if (send_to === undefined || send_to === "") {
      return "N/A";
    }

    let shippingDetails = {
      order_summary: missingShippingOrders.length > 0 ? missingShippingOrders : cartItems,
      country: send_to !== "Select your country" ? send_to : missingShippingOrdersDets.country,
    };

    const res = await axiosInstance
      .post("/shipping/getShippingCost", JSON.stringify(shippingDetails))
      .then((res: any) => {
        setShippingCost(res.data.cost);

        return res.data;
      })
      .catch((err: any) => {
        console.error(err);
      });
    return res;
  };

  const setApproval = async (spender: any, amount_to_spend: any, contract: Contract): Promise<TransactionResponse> => {
    let isApproved = await contract.approve(spender, amount_to_spend);
    return isApproved;
  };

  const approveWithToken = async (pay_with_pow: boolean) => {
    let token_allowance = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    let token_approval = null;
    setApprovalError("");

    try {
      setApprovalLoading(true);
      token_approval = await setApproval(
        merchContract.address,
        token_allowance,
        pay_with_pow ? powContract : punksContract
      );
      await token_approval.wait();
      pay_with_pow ? setPowApproved(true) : setPunksApproved(true);
      return 1;
    } catch (err: any) {
      console.error("Approval transaction error: ", err);
      setTransactionError("");
      setApprovalError("Approval failed. " + err.message || "Approval failed. " + err.code);
      return err;
    } finally {
      setApprovalLoading(false);
      console.log("Result from approval: ", token_approval);
    }
  };

  const checkAllowance = async (
    merch_contract: any,
    userAddress: string,
    token_contract: Contract
  ): Promise<Boolean> => {
    let check_allowance = await token_contract.allowance(userAddress, merch_contract);
    try {
      if (check_allowance._hex === "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const checkTokenAllowance = async (isPow: boolean) => {
    try {
      let check_token_allowance = null;
      check_token_allowance = await checkAllowance(merchContract.address, account, isPow ? powContract : punksContract);
      if (check_token_allowance) {
        isPow ? setPowApproved(true) : setPunksApproved(true);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const newBalAfterPurchse = async () => {
    /** Set Balances */
    const powCurrBalance = await powContract.balanceOf(account);
    const powNum = Number(ethers.utils.formatEther(powCurrBalance)).toFixed(2);
    setPowBalance(Number(powNum));

    const punksAmount = await punksContract.balanceOf(account);
    const punksBal = Number(ethers.utils.formatEther(punksAmount)).toFixed(2);
    setPunksBalance(Number(punksBal));
  };

  const simulate = async (...params: any) => {
    try {
      await merchContract.callStatic.purchase(...params, { from: await account });
      return 1;
    } catch (e) {
      return e;
    }
  };

  const payWithToken = async (items_buying: any, pay_with_pow: boolean) => {
    let transactionResult = null;
    let transactionState = null;
    setWalletInstructions(true);
    setTransactionError("");
    setApprovalError("");

    const getItemsID = async (items_buying: any) => {
      let items_purchasing = [0, 0, 0, 0, 0];

      items_buying.forEach((item: any) => {
        items_purchasing[item.item_id] = item.size_choice_idx;
      });

      return items_purchasing;
    };

    let items_purchasing = await getItemsID(items_buying);
    let token_balance = pay_with_pow ? powBalance : punksBalance;
    let amount_to_be_paid = pay_with_pow ? itemsPowSum : itemsPunksSum;

    let approvalBool;
    //approval check code here if user is not approved
    if (pay_with_pow) {
      if (!powApproved) {
        approvalBool = await approveWithToken(pay_with_pow);
        if (approvalBool !== 1) {
          return;
        }
      }
    } else {
      if (!punksApproved) {
        approvalBool = await approveWithToken(pay_with_pow);
        if (approvalBool !== 1) {
          return;
        }
      }
    }

    //pay with token code here
    if (token_balance >= amount_to_be_paid) {
      try {
        // pass in the same params required for purchase
        const simulation: Number | Error = (await simulate(items_purchasing, pay_with_pow)) as Number | Error;

        if (simulation !== 1) {
          setBackToShop(true);
          setTransactionError(
            "One or more items you selected are now sold out. Your bag has been cleared to prevent a failed transaction and any gas loss. Please return to the shop to proceed."
          );
          setCartAmount(0);
          setCartItems([]);
          window.localStorage.removeItem("Cart_Items");
          return;
        }
      } catch (error) {
        console.log("Error during simulation: ", error);
        setTransactionError("Error during simulation. Try again.");
        return;
      }

      try {
        setProcessLoading(true);
        transactionState = await merchContract.purchase(items_purchasing, pay_with_pow);
      } catch (err: any) {
        setTransactionError("Transaction failed. " + err.message || "Transaction failed. " + err.code);
        setProcessLoading(false);
        setWalletInstructions(false);
        console.error("Contract payment error: ", err);
        return;
      } finally {
        setTransactionPayState(transactionState);
      }

      try {
        transactionResult = await transactionState.wait();
        if (transactionResult.status === 1) {
          setPaymentOption(pay_with_pow ? "POW" : "PUNKS");
          setPaymentAmt(pay_with_pow ? itemsPowSum : itemsPunksSum);
          userItemsDetails(amount_to_be_paid, pay_with_pow ? "pow" : "punks");
          setCheckedPayment(true);
          setActiveStep("shippingStep");
          newBalAfterPurchse();
          setCartAmount(0);
          setCartItems([]);
          window.localStorage.removeItem("Cart_Items");
        }
      } catch (err: any) {
        setProcessLoading(false);
        setWalletInstructions(false);
        setTransactionError("The transaction failed, was sped up or rejected. Try again.");
        return;
      } finally {
        setTransactionPayState(transactionResult);
        setProcessLoading(false);
        if (!transactionResult) return;
      }
    } else if (token_balance <= amount_to_be_paid) {
      console.error("Did not meet fund requirements");
    }
    return;
  };

  const handleCountrySubmission = () => {
    try {
      getShippingEstimate(selectedCountry);
      setCheckedCountry(true);
      const searchIndex = countries.findIndex((country: any) => country.code == selectedCountry);
      setDisplayCountry(countries[searchIndex].name);

      if (checkedPayment) {
        setActiveStep("shippingStep");
        console.log("RUN IT WITH NEW COUNTRY ", selectedCountry);
        createNewSession(selectedCountry);
      } else {
        setActiveStep("orderStep");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleStripeURLClick = () => {
    window.open(stripeURL, "_self");
  };

  const handleCartItemRemove = (idx: any) => {
    let newArr = cartItems.filter((item: any) => {
      return item !== idx;
    });

    if (cartAmount === 0) {
      setCartAmount(0);
    } else {
      setCartAmount(cartAmount - 1);
    }

    setCartItems(newArr);
    window.localStorage.setItem("Cart_Items", JSON.stringify(newArr));
  };

  const handleEditButton = () => {
    setActiveStep("countryStep");
    setCheckedCountry(false);
  };

  const loadingStatus = (waitingApproval: boolean, waitingPayment: boolean, defaultMsg: string) => {
    if (waitingApproval) {
      return "Approving...";
    } else if (waitingPayment) {
      return "Processing...";
    } else {
      return defaultMsg;
    }
  };

  const merchImages = [
    { imgSrc: PV_HOODIE_FRONT },
    { imgSrc: PUNKS_HAT_FRONT },
    { imgSrc: PUNKS_SHIRT_FRONT },
    { imgSrc: MH_HAT_FRONT },
    { imgSrc: MH_SHIRT_FRONT },
  ];

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <AlternateNavbar />
      <div className="merch-banner">
      </div>
      <main className="merch-page">
        {/* Landing  */}
        <div className="checkout-page-layout">
          {/* Checkout Steps */}
          <div className="checkout-steps-flow">
            <div className="checkout-page-header">Checkout</div>
            {/* Connect Wallet Step */}
            <div className="checkout-step">
              <div className={activeStep === "walletStep" ? "checkout-step-title opened" : "checkout-step-title"}>
                {checkedWallet ? <img src={checkMark} alt="check mark" /> : null}
                <h2>Connect Your Wallet</h2>
                {checkedWallet ? <span>{truncateAddress(account)}</span> : null}
              </div>
              <div className={activeStep === "walletStep" ? "checkout-step-info opened" : "checkout-step-info"}>
                <ButtonComponent
                  onConnect={() => handleNextStep(account ? "countryStep" : "walletStep")}
                  label="Connect"
                  isConnectButton={true}
                />
              </div>
            </div>
            {/* Select Country*/}
            <div className="checkout-step">
              <div className={activeStep === "countryStep" ? "checkout-step-title opened" : "checkout-step-title"}>
                {checkedCountry ? <img src={checkMark} alt="check mark" /> : null}
                <h2>Shipping Estimation</h2>
                {checkedCountry ? (
                  <div className="text-edit-cont">
                    <div>
                      <span>{displayCountry}</span>
                    </div>
                    <div>
                      <span>
                        ~$
                        {shippingCost?.toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 2 })}
                      </span>
                      {checkedCountry ? (
                        <button className="checkout-edit-btn" type="button" onClick={() => handleEditButton()}>
                          Edit
                          <img src={editPencil} alt="check mark" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={activeStep === "countryStep" ? "checkout-step-info opened" : "checkout-step-info"}>
                <>
                  <div className="opened-shipping-estimate">
                    <div className="country-step-info-text">Select your Country</div>
                    <div className="payment-options-buttons ship-est-btns">
                      <select
                        required
                        value={selectedCountry}
                        onChange={(e: any) => {
                          handleCountrySelect(e.target.value);
                        }}
                        id="country-select"
                        name="country"
                      >
                        <option value="">Select your country</option>
                        {countries.map((currCountry: any, index: number) => (
                          <option key={index} value={currCountry.code}>
                            {currCountry.name}
                          </option>
                        ))}
                      </select>
                      <button
                        disabled={selectedCountry === "Select your country" ? true : false}
                        onClick={() => handleCountrySubmission()}
                        className="payment-option-btn"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </>
              </div>
            </div>
            {/* Order Payment */}
            <div className="checkout-step">
              <div className={activeStep === "orderStep" ? "checkout-step-title opened" : "checkout-step-title"}>
                {checkedPayment ? <img src={checkMark} alt="check mark" /> : null}
                <h2>Order Payment</h2>
                {checkedPayment ? (
                  <div>
                    Payment of{" "}
                    {missingShippingOrders.length === 0 ? (
                      <>
                        {paymentOption == "PUNKS"
                          ? `${paymentAmt?.toLocaleString("en-US")} $PUNKS`
                          : `${paymentAmt?.toLocaleString("en-US")} POW`}
                      </>
                    ) : (
                      <>
                        {missingShippingOrdersDets?.total_price} $
                        {missingShippingOrdersDets?.price_choice.toLocaleUpperCase()}
                      </>
                    )}{" "}
                    received
                  </div>
                ) : null}
              </div>

              <div className={activeStep === "orderStep" ? "checkout-step-info opened" : "checkout-step-info"}>
                {backToShop ? (
                  <>
                    {transactionError ? (
                      <div className="checkout-step-info-text transaction-error-message">{transactionError}</div>
                    ) : null}
                    <button onClick={handleRefresh} className="payment-option-btn">
                      <NavLink className="order-to-shop" to="/">
                        Go to Shop
                      </NavLink>
                    </button>
                  </>
                ) : (
                  <>
                    {cartAmount > 0 ? (
                      <>
                        {transactionError ? (
                          <div className="checkout-step-info-text transaction-error-message">{transactionError}</div>
                        ) : null}
                        {approvalError ? (
                          <div className="checkout-step-info-text transaction-error-message">{approvalError}</div>
                        ) : null}
                        {}
                        <div className="merch-user-messages">
                          <div className="merch-checkout-disclaimer">
                            Before you proceed: Your order will paid entirely in the token selected below. Once you have
                            processed your order payment in $POW or $PUNKS, you will be directed to provide shipping
                            details and payment via credit card. Since all $POW and $PUNKS will be returned to their
                            respective treasuries, all sales are final. For more information, please refer to our{" "}
                            <NavLink id="info-link" target="_blank" to="/shop-info">
                              Info page.
                            </NavLink>
                          </div>
                        </div>
                        <br />
                        {walletInstructions ? (
                          <div style={{ marginBottom: "1em" }} className="checkout-step-info-text">
                            Follow wallet instructions. Two transactions are necessary to complete this step (POW/PUNKS
                            approval and order payment).
                          </div>
                        ) : null}

                        <div className="payment-options-buttons">
                          <div className="payment-options-button">
                            <button
                              type="button"
                              onClick={() => payWithToken(cartItems, false)}
                              className={
                                approvalLoading || processLoading || notEnoughPunks
                                  ? "payment-option-btn-disabled"
                                  : "payment-option-btn"
                              }
                              disabled={approvalLoading || processLoading || notEnoughPunks}
                            >
                              Pay {itemsPunksSum.toLocaleString("en-US")} $PUNKS
                            </button>
                            <div style={{ display: !notEnoughPunks && windowWidth < 450 ? "none" : "flex" }}>
                              {notEnoughPunks ? "Insufficient Funds" : <br></br>}
                            </div>
                          </div>
                          <div className="payment-options-button">
                            <button
                              type="button"
                              onClick={() => payWithToken(cartItems, true)}
                              className={
                                approvalLoading || processLoading || notEnoughPow
                                  ? "payment-option-btn-disabled"
                                  : "payment-option-btn"
                              }
                              disabled={processLoading || approvalLoading || notEnoughPow}
                            >
                              Pay {itemsPowSum.toLocaleString("en-US")} $POW
                            </button>
                            <div style={{ display: !notEnoughPow && windowWidth < 450 ? "none" : "flex" }}>
                              {notEnoughPow ? "Insufficient Funds" : <br></br>}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="checkout-step-info-text">
                          There are no items in your order. Add merch in the shop!
                        </div>
                        <button className="payment-option-btn">
                          <NavLink className="order-to-shop" to="/shop">
                            Go to Shop
                          </NavLink>
                        </button>
                      </>
                    )}
                  </>
                )}

                {/* Order payment error message area */}
                <div className="merch-user-messages">
                  <div>{loadingStatus(approvalLoading, processLoading, ``)}</div>
                  <div className="transaction-error-message">{stripeURLError ? "Error getting stripe link" : null}</div>
                </div>
              </div>
            </div>
            {/* Stripe Payment*/}
            <div className="checkout-step">
              <div className={activeStep === "shippingStep" ? "checkout-step-title opened" : "checkout-step-title"}>
                <h2>Shipping Payment</h2>
              </div>
              <div className={activeStep === "shippingStep" ? "checkout-step-info opened" : "checkout-step-info"}>
                <div className="checkout-step-info-text">
                  You've selected <strong>{displayCountry}</strong> as your destination.
                </div>
                <button type="button" onClick={() => handleStripeURLClick()} className="payment-option-btn">
                  Pay for shipping
                </button>
              </div>
            </div>
          </div>
          {/* Order Summary */}
          <div className="order-summary">
            <div className="top-order-cont">
              <div className="top-order-heading">
                <h2>Order Summary</h2>
                <span>Please review your items below.</span>
              </div>
              <div className="top-order-items">
                {missingShippingOrders.length === 0 ? (
                  <>
                    {cartItems.map((item: any) => (
                      <div key={item.item_id} className="order-item-cont">
                        <img src={item.item_img} alt="item" />
                        <div className="single-item-info">
                          <h4>{item.item_name}</h4>
                          <div className="single-item-price">
                            <img src={punksMoney} alt="punks" />
                            <span>{item.price_punks.toLocaleString("en-US")}</span>
                            <img src={powMoney} alt="pow" />
                            <span>{item.price_pow.toLocaleString("en-US")}</span>
                            <div color="white">Size: {item.size_choice}</div>
                          </div>
                        </div>
                        <div className="x-remove" onClick={() => handleCartItemRemove(item)}>
                          x
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {missingShippingOrders.map((item: any) => (
                      <div key={item.item_id} className="order-item-cont">
                        <img src={merchImages[item.item_id].imgSrc} alt="item" />
                        <div className="single-item-info">
                          <h4>{item.name}</h4>
                          <div className="single-item-price">
                            <img src={punksMoney} alt="punks" />
                            <span>{item.price_punks.toLocaleString("en-US")}</span>
                            <img src={powMoney} alt="pow" />
                            <span>{item.price_pow.toLocaleString("en-US")}</span>
                            <div>Size: {item.size_choice}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="bottom-order-cont">
              <span style={{ opacity: "0%" }}>Total:</span>
              <div className="order-total">
                <span>Order Total:</span>
                <span>
                  {missingShippingOrders.length === 0 ? (
                    <>
                      <img src={punksMoney} alt="punks" /> {itemsPunksSum.toLocaleString("en-US")} $punks &#8195;
                      <span className="or-text">or</span>&#8195;
                      <img src={powMoney} alt="pow" />
                      {itemsPowSum.toLocaleString("en-US")} $pow
                    </>
                  ) : (
                    <>
                      <img src={missingShippingOrdersDets.price_choice === "pow" ? powMoney : punksMoney} alt="punks" />{" "}
                      {missingShippingOrdersDets.total_price.toLocaleString("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                      })}{" "}
                      ${missingShippingOrdersDets.price_choice.toLocaleUpperCase()}
                    </>
                  )}
                </span>
              </div>
              {shippingCost ? (
                <div className="shipping-total">
                  <span>Shipping:</span>
                  <span>
                    ~$
                    {shippingCost?.toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 2 })} USD
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </main>
      <AlternateFooterSection />
    </>
  );
};

export default MerchCheckout;
