import { BigNumber } from "@ethersproject/bignumber/lib/bignumber";
import { ethers } from "ethers";

export const truncateAddress = (address: string) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num: number) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const getFormattedNumber = (num: BigNumber): number => {
  return parseInt(ethers.utils.formatEther(num));
}

export const getIndicesArrayFromNum = (num: number) => {
  let result = [];
  for (let i = 0; i <= num; i++) {
    result.push(i);
  }
  return result;
};