import { ReactElement, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { IS_MAINNET } from "context/dataContext";
import useData from "context/dataContext";
import { OSContext } from "context/osContext";
import { contractAddresses, networks } from "./networkConstants";
import originStories1Abi from "blockchain/abi/originStories1Abi.json";
import originStories2Abi from "blockchain/abi/originStories2Abi.json";
import originStories3Abi from "blockchain/abi/originStories3Abi.json";

type Props = {
  live: boolean;
};

export const OSContractHelpers = ({ live }: Props): ReactElement => {
  const { account, signer, setNetwork } = useData();

  const {
    setOS1ComicBalance,
    setOS1ComicContract,
    setOS2ComicBalance,
    setOS2ComicContract,
    setOS3ComicBalance,
    setOS3ComicContract,
    setOS1ComicTotalSupply,
    setOS2ComicTotalSupply,
    setOS3ComicTotalSupply,
  } = useContext(OSContext);

  const contracts = IS_MAINNET ? contractAddresses.mainnet : contractAddresses.rinkeby;
  const network = IS_MAINNET ? networks.mainnet : networks.rinkeby;
  const infuraProvider = new ethers.providers.InfuraProvider(network.name, network.apiKey);

  useEffect(() => {
    setNetwork(network);
    if (live) initializeContractData();
  }, [account]);

  const initializeContractData = async () => {
    try {
      const os1ComicReadOnlyContract = new ethers.Contract(contracts.os1, originStories1Abi, infuraProvider);
      const os2ComicReadOnlyContract = new ethers.Contract(contracts.os2, originStories3Abi, infuraProvider);
      const os3ComicReadOnlyContract = new ethers.Contract(contracts.os3, originStories3Abi, infuraProvider);
      const os1ComicContract = new ethers.Contract(contracts.os1, originStories1Abi, signer);
      const os2ComicContract = new ethers.Contract(contracts.os2, originStories2Abi, signer);
      const os3ComicContract = new ethers.Contract(contracts.os3, originStories3Abi, signer);

      setOS1ComicContract(os1ComicContract);
      setOS2ComicContract(os2ComicContract);
      setOS3ComicContract(os3ComicContract);

      /** Set Total Supply Values */

      const os1TotalSupplyBigNum = await os1ComicReadOnlyContract.totalSupply();
      setOS1ComicTotalSupply(parseInt(os1TotalSupplyBigNum));

      const os2TotalSupplyBigNum = await os2ComicReadOnlyContract.totalSupply();
      setOS2ComicTotalSupply(parseInt(os2TotalSupplyBigNum));

      const os3TotalSupplyBigNum = await os3ComicReadOnlyContract.totalSupply();
      setOS3ComicTotalSupply(parseInt(os3TotalSupplyBigNum));

      /** Set Balances */
      const os1ComicBalanceBigNum = await os1ComicContract.balanceOf(account);
      const os1ComicAmount = parseInt(os1ComicBalanceBigNum);
      setOS1ComicBalance(os1ComicAmount);

      const os2ComicBalanceBigNum = await os2ComicContract.balanceOf(account);
      const os2ComicAmount = parseInt(os2ComicBalanceBigNum);
      setOS2ComicBalance(os2ComicAmount);

      const os3ComicBalanceBigNum = await os3ComicContract.balanceOf(account);
      const os3ComicAmount = parseInt(os3ComicBalanceBigNum);
      setOS3ComicBalance(os3ComicAmount);
    } catch (err: any) {
      console.log(err);
    }
  };
  return <></>;
};

export default OSContractHelpers;
