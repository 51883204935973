import React, { useEffect } from "react";
import { useState } from "react";
import { Contract, ethers } from "ethers";
import Web3Modal from "web3modal";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { networks } from "utils/networkConstants";
import { axiosInstancePunksComic } from "axiosHelper/axios";

export const IS_MAINNET = true; // TO TOGGLE WHEN LAUNCHING
const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Punkscomic",
      infuraId: process.env.REACT_APP_INFURA_KEY,
      chainId: IS_MAINNET ? 1 : 5,
    },
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_KEY,
    },
  },
};

const DataContext = React.createContext<any | null>(null);

const web3modal = new Web3Modal({
  providerOptions,
  disableInjectedProvider: false,
  cacheProvider: false,
  network: IS_MAINNET ? networks.mainnet.name : networks.goerli.name,
  theme: "dark",
});

function useData() {
  const [isWalletSuspicious, setIsWalletSuspicious] = useState(false);

  /** contracts */
  const [apeCoinContract, setApeCoinContract] = useState<Contract>();
  const [apeComicContract, setApeComicContract] = useState<Contract>();
  const [apeMadnessContract, setApeMadnessContract] = useState<Contract>();

  /** balances */
  const [comic1Balance, setComic1Balance] = useState<number>();
  const [comic3Balance, setComic3Balance] = useState<number>();
  const [specialComic3Balance, setSpecialComic3Balance] = useState<number>();
  const [holoComic3Balance, setHoloComic3Balance] = useState<number>();
  const [mp1Balance, setMP1Balance] = useState<number>();
  const [metaheroGenBalance, setMetaheroGenBalance] = useState<number>();
  const [metaheroCoresBalance, setMetaheroCoresBalance] = useState<number>();
  const [foundersDAOBalance, setFoundersDAOBalance] = useState<number>();
  const [eliteApeCoinBalance, setEliteApeCoinBalance] = useState<number>();
  const [apeComicBalance, setApeComicBalance] = useState<number>(0);

  /** network provider, wallet and account */
  const [account, setAccount] = useState<any>();
  const [provider, setProvider] = useState<ethers.providers.Web3Provider>();
  const [chainId, setChainId] = useState<number>();
  const [network, setNetwork] = useState<number>();
  const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner>();
  const [walletSelection, setWalletSelection] = useState<any>();
  const [isMainnet, setIsMainnet] = useState(false);

  const connectWallet = async () => {
    await web3modal.clearCachedProvider();
    const walletSelection = await web3modal.connect();
    setWalletSelection(walletSelection);
    const provider = new ethers.providers.Web3Provider(walletSelection);
    setProvider(provider);
    const signer = provider.getSigner();
    setSigner(signer);
    const accounts = await provider.listAccounts();
    const account = accounts[0];
    setAccount(account);
  };

  const refreshState = () => {
    setAccount(account);
    setChainId(chainId);
    setNetwork(network);
  };

  const disconnect = async () => {
    await web3modal.clearCachedProvider();
    refreshState();
  };

  useEffect(() => {
    if (walletSelection) {
      setAccount(walletSelection.selectedAddress);
      verifySuspiciousWallet(walletSelection.selectedAddress);
    }

  }, [walletSelection]);

  useEffect(() => {
    web3modal.clearCachedProvider();
  }, []);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = () => {
        if (walletSelection) {
          setAccount(walletSelection.selectedAddress)
        };
        window.localStorage.removeItem("Cart_Items");
        window.location.reload();
        
        if(walletSelection) {
          verifySuspiciousWallet(walletSelection.selectedAddress);
        }
      };

      const handleChainChanged = (_hexChainId: React.SetStateAction<number | undefined>) => {
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        console.log("disconnect error");
        disconnect();
        setWalletSelection({});
        setAccount("");
        setProvider(undefined);
        setSigner(undefined);
        setIsWalletSuspicious(false);
        window.location.reload();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      if (window.ethereum && window.ethereum.on) {
        window.ethereum.on("accountsChanged", handleAccountsChanged);
        window.ethereum.on("chainChanged", (chainId: any) => {
          window.location.reload();
        });
      }

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
    // eslint-disable-next-line
  }, [provider, account]);

  const verifySuspiciousWallet = async (account: any) => {
    let userWallet = account;
    if (!account) return;
    await axiosInstancePunksComic.get(`/audit-wallet.php?addr=${userWallet}`).then(data => {
      let walletData = data.data[0];
      console.log(walletData.rating);
      (walletData.rating === "unknown" || walletData.rating === "lowRisk") ? setIsWalletSuspicious(false) : setIsWalletSuspicious(true);
    });
  };

  return {
    account,
    setAccount,
    provider,
    setProvider,
    signer,
    setSigner,
    comic1Balance,
    setComic1Balance,
    mp1Balance,
    setMP1Balance,
    comic3Balance,
    setComic3Balance,
    specialComic3Balance,
    setSpecialComic3Balance,
    holoComic3Balance,
    setHoloComic3Balance,
    metaheroGenBalance,
    setMetaheroGenBalance,
    metaheroCoresBalance,
    setMetaheroCoresBalance,
    foundersDAOBalance,
    setFoundersDAOBalance,
    eliteApeCoinBalance,
    setEliteApeCoinBalance,
    apeComicBalance,
    setApeComicBalance,
    apeCoinContract,
    setApeCoinContract,
    apeComicContract,
    setApeComicContract,
    apeMadnessContract,
    setApeMadnessContract,
    connectWallet,
    isMainnet,
    setIsMainnet,
    setNetwork,
    network,
  };
}

export const DataProvider: React.FC<any> = ({ children }) => {
  const data = useData();

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export default function DataConsumer() {
  return React.useContext(DataContext);
}
