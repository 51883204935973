import React, { useState, createContext, ReactElement } from "react";
import { Contract } from "ethers";

export const OSContext = createContext<any | null>(null);

type Props = {
  children: ReactElement;
};

export const OSProvider = ({ children }: Props) => {
  /** contracts */
  const [os1ComicContract, setOS1ComicContract] = useState<Contract>();
  const [os2ComicContract, setOS2ComicContract] = useState<Contract>();
  const [os3ComicContract, setOS3ComicContract] = useState<Contract>();

  /** balances */
  const [os1ComicBalance, setOS1ComicBalance] = useState<number>(0);
  const [os2ComicBalance, setOS2ComicBalance] = useState<number>(0);
  const [os3ComicBalance, setOS3ComicBalance] = useState<number>(0);

  /** total supply */
  const [os1ComicTotalSupply, setOS1ComicTotalSupply] = useState(0);
  const [os2ComicTotalSupply, setOS2ComicTotalSupply] = useState(0);
  const [os3ComicTotalSupply, setOS3ComicTotalSupply] = useState(0);

  return (
    <OSContext.Provider
      value={{
        os1ComicBalance,
        setOS1ComicBalance,
        os2ComicBalance,
        setOS2ComicBalance,
        os3ComicBalance,
        setOS3ComicBalance,
        os1ComicContract,
        setOS1ComicContract,
        os2ComicContract,
        setOS2ComicContract,
        os3ComicContract,
        setOS3ComicContract,
        os1ComicTotalSupply,
        setOS1ComicTotalSupply,
        os2ComicTotalSupply,
        setOS2ComicTotalSupply,
        os3ComicTotalSupply,
        setOS3ComicTotalSupply,
      }}
    >
      {children}
    </OSContext.Provider>
  );
};
