import { useState } from "react";
import "./Accordion.css";

interface AccordionInterface {
  question: string;
  answer: string;
  answer2?: string;
  atag1?: string;
  atag2?: string;
  isLineBreaks?: boolean;
  answer3?: string;
}

const Accordion: React.FC<AccordionInterface> = ({
  question,
  answer,
  answer2,
  atag1,
  atag2,
  isLineBreaks,
  answer3,
}) => {
  const [activeIdx, setActiveIdx] = useState(false);

  const toggleOpen = (i: any) => {
    activeIdx ? setActiveIdx(false) : setActiveIdx(true);
  };

  return (
    <div className="merch-accordion">
      <div className="accord-item-cont">
        <div className="accord-item-question" onClick={() => toggleOpen(activeIdx)}>
          <span>{activeIdx ? "-" : "+"}</span>
          <h2>{question}</h2>
        </div>
        <div className={activeIdx ? "accord-item-answer opened" : "accord-item-answer"}>
          {activeIdx ? (
            <>
              {isLineBreaks ? (
                <>
                  {answer}
                  <br />
                  {answer2}
                  <br />
                  {answer3}
                </>
              ) : (
                <>
                  {!answer2 ? (
                    <>{answer}</>
                  ) : (
                    <>
                      {answer}
                      <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }} href={atag1}>
                        {" "}
                        UPDAO
                      </a>{" "}
                      and{" "}
                      <a style={{ color: "white", textDecoration: "underline", cursor: "pointer" }} href={atag2}>
                        $PUNKS
                      </a>{" "}
                      {answer2}
                    </>
                  )}
                </>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
