import purplePVlogo from "../../images/purplePVlogo.svg";
import purpleTwitter from "../../images/purpleTwitter.svg";
import purpleDiscord from "../../images/purpleDiscord.svg";
import "./FooterSection.css";

const AlternateFooterSection: React.FC = () => {
  return (
    <footer className="alt-footer">
      <div className="alt-footer-icons">
        <div>
          <a target="_blank" rel="noreferrer" href="https://pixelvault.com/">
            <img className="alt-icon-img" src={purplePVlogo} alt="Pixel Vault logo" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://pixelvault.com/">
            pixelvault.com
          </a>
        </div>
        <div>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/pixelvault_">
            <img className="alt-icon-img" src={purpleTwitter} alt="Twitter logo" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/pixelvault_">
            @pixelvault_
          </a>
        </div>
        <div>
          <a target="_blank" rel="noreferrer" href="https://discord.gg/pixelvault">
            <img className="alt-icon-img" src={purpleDiscord} alt="Discord logo" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://discord.gg/pixelvault">
            Pixel Vault
          </a>
        </div>
      </div>
      <hr className="horz-line" />
      <div className="alt-footer-links">
        <span>&copy; Pixel Vault Inc.</span>
        <div>
          <a className="footer-shop-links" href="mailto:support@pixelvault.com">
            Contact
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            className="alt-footer-legals"
            href="https://drive.google.com/file/d/1-75HaHsa5g-iGPtUoMlz-JcyRTPTGdF1/view?usp=sharing"
          >
            Terms
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="alt-footer-legals"
            href="https://drive.google.com/file/d/1-6AgzCeOC7y1JdwZv9RQHOQ_mrBDKp29/view?usp=sharing"
          >
            Privacy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default AlternateFooterSection;
