import { ReactElement } from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import OriginStories from "ui/OriginStories";
import ReadOS3Comic from "ui/ReadOS3Comic";
import PublicReadComponent from "ui/Read/PublicReadComponent";
import Navbar from "../components/Navbar/Navbar";
import ApeTownPage from "../ui/EliteApeTown/ApeTownPage";
import Landing from "../ui/Landing";
import MerchPage from "../ui/Merch/MerchPage";
import MerchInfo from "ui/Merch/MerchInfo/MerchInfo";
import MerchCheckout from "ui/Merch/MerchCheckout/MerchCheckout";
import { TheComics } from "ui/consts";
import MerchSuccess from "ui/Merch/MerchSuccess/MerchSuccess";
import useData from "context/dataContext";
import ForbiddenPage from "ui/common/ForbiddenPage";

type WithHeaderProps = {
  transparent?: boolean;
  children: ReactElement;
};

const WithHeader = ({ transparent, children }: WithHeaderProps): ReactElement => (
  <>
    <Navbar transparent={transparent} />
    {children}
  </>
);

type AppProps = {
  live: boolean;
};

const AppRoutes = ({ live }: AppProps): ReactElement => {
  const { isWalletSuspicious } = useData();

  return (
  <Router>
    <Routes>
      <Route path="*" element={isWalletSuspicious && <Navigate to="/forbidden" replace />} />   
      <Route path="/" element={<MerchPage />} />
      <Route path="/shop" element={<MerchPage />} />
      <Route path="/shop-info" element={<MerchInfo />} />
      <Route path="/shop-checkout" element={<MerchCheckout />} />
      <Route path="/success*" element={<MerchSuccess />} />
    </Routes>
  </Router>
)};

export default AppRoutes;
