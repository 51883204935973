import { ReactElement, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import useData from "../../context/dataContext";
import { MerchContext } from "context/merchContext";

//COMPONENTS
import CartModal from "components/CartModal/CartModal";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import WrongNetwork from "components/WrongNetworkPopup/WrongNetwork";
//ASSETS
import PixelVaultLogo from "images/PixelVaultLogo.svg";
import profileImg from "../../images/ProfileImg.svg";
import shoppingBag from "../../images/bag.svg";
import "./Navbar.css";

type Props = {
  transparent?: boolean;
};

const AlternateNavbar = ({ transparent }: Props): ReactElement => {
  const { account } = useData();
  const { cartAmount, powBalance, punksBalance, missingShippingOrders } = useContext(MerchContext);
  const [navbar, setNavbar] = useState<boolean>(transparent ? transparent : false);
  const [openCartModal, setOpenCartModal] = useState(false);
  const windowWidth = window.innerWidth;

  const showCart = () => {
    console.log("thingy@@: ", missingShippingOrders.length);
    if (account) {
      setOpenCartModal(!openCartModal);
    }

    const bagBtn = document.querySelectorAll(".dialog-container .button");

    document.querySelector(".navbar-icons .btn")?.classList.add("nudge-btn");

    for (let i = 0; i < bagBtn.length; i++) {
      bagBtn[i].classList.add("nudge-btn");
    }

    setTimeout(() => {
      document.querySelector(".navbar-icons .btn")?.classList.remove("nudge-btn");
      for (let i = 0; i < bagBtn.length; i++) {
        bagBtn[i].classList.remove("nudge-btn");
      }
    }, 3000);
  };
  const handleMenuClick = () => {
    const hamburger = document.querySelector(".alt-hamburger");
    const navbarMenu = document.querySelector(".alt-navbar-menu");

    hamburger?.classList.toggle("active");
    navbarMenu?.classList.toggle("active");
  };

  const changeNavbarBackground = () => (window.scrollY >= 20 ? setNavbar(true) : setNavbar(false));

  window.addEventListener("scroll", changeNavbarBackground);

  return (
    <header style={{ zIndex: 1 }} className="alt-header">
      <nav className={navbar ? "alt-navbar-background" : "alt-navbar-transparent"}>
        <div className="nav-wrap">
          <div className="left-of-nav">
            {/* Check Network */}
            {/* {!account ? null : <WrongNetwork />} */}

            <img className="navbar-pv-logo" src={PixelVaultLogo} alt="The Pixel Vault Logo" />

            <div className="vert-line"></div>
            <span className="left-nav-header">
              Official {windowWidth > 600 ? <br /> : null}
              Merch Store
            </span>
          </div>

          <ul onClick={handleMenuClick} className="alt-navbar-menu">
            <li className="navbar-item">
              <NavLink className={({ isActive }) => (isActive ? "navbar-alt-link-active" : "navbar-alt-link")} to="/">
                Shop
              </NavLink>
            </li>
            <li className="navbar-item">
              <NavLink
                className={({ isActive }) => (isActive ? "navbar-alt-link-active" : "navbar-alt-link")}
                to="/shop-info"
              >
                Info
              </NavLink>
            </li>
            {windowWidth > 715 || !account ? null : (
              <li className="navbar-item">
                <span style={{ fontSize: "20px" }} className="left-nav-header acc-bal">
                  Balance:
                </span>
                <br />
                <span style={{ fontSize: "20px" }} className="left-nav-header acc-bal">
                  {punksBalance} $PUNKS <br />
                  {powBalance} $POW{" "}
                </span>
              </li>
            )}
          </ul>

          <div className="navbar-icons">
            {!account ? null : (
              <>
                <span className="left-nav-header acc-bal">Balance:</span>{" "}
                <span className="left-nav-header acc-bal">
                  {punksBalance} $PUNKS <br />
                  {powBalance} $POW{" "}
                </span>
              </>
            )}
            {!account ? (
              <ButtonComponent label="Connect" isConnectButton={true} />
            ) : (
              <img className="profile-icon" src={profileImg} alt="profile icon" />
            )}
            <div className="cart-cont">
              <img onClick={showCart} className="shopping-bag" src={shoppingBag} alt="shopping bag" />
              {missingShippingOrders.length > 0 ? (
                <span className="cart-amount-yellow">!</span>
              ) : cartAmount > 0 ? (
                <span className="cart-amount">{cartAmount}</span>
              ) : null}
            </div>
          </div>
          <CartModal open={openCartModal} />
          <div onClick={handleMenuClick} className="alt-hamburger">
            <span className="alt-bar"></span>
            <span className="alt-bar"></span>
            <span className="alt-bar"></span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default AlternateNavbar;
