import "./ButtonComponent.css";
import useData from "../../context/dataContext";
import clsx from "clsx";

type ButtonComponentProps = {
  label: string;
  link?: string;
  target?: string;
  isConnectButton?: boolean;
  onConnect?: () => void;
  className?: string;
};

const ButtonComponent: React.FC<ButtonComponentProps> = (props: ButtonComponentProps) => {
  const { label: text, link, target, isConnectButton, onConnect, className: classNameProp } = props;

  const handleConfirm = (): void => {
    try {
      if (onConnect) onConnect();
    } catch (err) {
      console.log(err);
    }
  };

  const { connectWallet } = useData();
  return !isConnectButton ? (
    <button className={clsx("btn", classNameProp)} onClick={handleConfirm}>
      <a target={target} href={link}>
        {text}
      </a>
    </button>
  ) : (
    <button
      className={clsx("btn", classNameProp)}
      onClick={async () => {
        handleConfirm();
        await connectWallet();
      }}
    >
      {text ? text : "Connect wallet"}
    </button>
  );
};

export default ButtonComponent;
