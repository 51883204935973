import { ReactElement, useEffect } from "react";
// Components
import AlternateFooterSection from "components/FooterSection/AlternateFooterSection";
import Accordion from "components/Accordion/Accordion";
import AlternateNavbar from "components/Navbar/AlternateNavbar";
// CSS
import "../MerchPage.css";
import "./MerchInfo.css";
// Assets
import TShirtSizes from "images/TShirtSizes.png";
import HoodieSizes from "images/HoodieSizes.png";

const MerchInfo = (): ReactElement => {
  // Close the shop switch
  let shopClosed = true;

  useEffect(() => {
    if (window.location.pathname === "/shop-info") {
      const appEL = document.querySelector<HTMLElement>(".App");
      appEL!.style.backgroundColor = "#000000";
    }
  }, []);

  return (
    <>
      <AlternateNavbar />
      <div className="merch-banner"></div>
      <main className="merch-page">
        {/* Landing  */}
        <div className="merch-info-container">
          <div className="merch-sidebar">
            <a href="#quantities">HOW TO BUY</a>
            <a href="#fit">FIT</a>
            <a href="#faqs">FAQs</a>
          </div>
          <div className="merch-info-content">
            <div id="quantities">
              <div className="info-merch-title">HOW TO BUY</div>
              <p className="info-merch-text">
                Items may only be purchased using $POW or $PUNKS. The entirety of your order must be purchased using one
                token or the other, you may not combine them. <br /> <br /> Items are available on a first come, first
                served basis. The pre-order window will open on September 26, 2022 at 4pm ET and will close on September
                28, 2022 at 4pm EDT, or while supplies last. <br /> <br />
                Orders are expected to ship out in October.
              </p>
            </div>
            <div>
              <div id="fit" className="info-merch-title">
                FIT
              </div>
              <p className="info-merch-text">
                All items are considered “unisex” and fit true to size. See below for size charts specifically for
                t-shirts and hoodies. Caps are one size fits most.
              </p>
              <div className="size-charts">
                <img src={TShirtSizes} alt="t-shirt size chart" />
                <br />
                <br />
                <img src={HoodieSizes} alt="hoodie size chart" />
              </div>
            </div>
            <div id="faqs">
              <div className="info-merch-title">FAQs</div>
              <Accordion
                question={"What is your refund policy?"}
                answer={
                  "All token revenue generated from this drop will be directed to their respective treasuries ($POW to be sent to the"
                }
                atag1={"https://etherscan.io/address/0x19c30ad5ea4f7f9f36a8662b5fa2cbc09e55fded"}
                atag2={"https://etherscan.io/address/0x30103ba8d2bd2280144b72401170834e0c608086"}
                answer2={
                  "to be sent to the $PUNKS treasury), and all sales are final. If an order is canceled for any reason, shipping costs will be refunded via the original payment method. Once the cancellation is processed, it may take between 5 - 10 business days for your financial institution to reflect the refund."
                }
              />
              <Accordion
                question={"Is there a limit to the amount of merchandise I can purchase?"}
                answer={
                  "Each holder may purchase up to one of each item for a total of five unique items per wallet, with a maximum of two wallets per household. Any orders that exceed these limits may be canceled at Pixel Vault’s discretion, and any $POW or $PUNKS spent will be forfeited."
                }
              />
              <Accordion
                question={"How does shipping work?"}
                answer={
                  "Shipping must be paid through a separate credit card transaction. $POW and $PUNKS will not be accepted. Packages domestically shipped within the US will cost $15 for shipping. Shipping costs for international orders vary by region and weight of products ordered. Depending on the order and shipping destination, you can expect to pay between $15 - $68."
                }
              />
              <Accordion
                question={"When will the items ship?"}
                answer={
                  "All items are sold on a pre-order basis. Orders are expected to ship in October. Shipping confirmation will be sent to the email address provided during the checkout process when your shipping label is created."
                }
              />
              <Accordion
                question={"How much will the items cost in $POW and $PUNKS?"}
                isLineBreaks={true}
                answer={"Hats: 1,000 $POW / 300 $PUNKS"}
                answer2={"T-Shirts: 1,000 $POW / 300 $PUNKS"}
                answer3={"Hoodies: 2,500 $POW / 750 $PUNKS"}
              />
              <Accordion
                question={"Why am I seeing an error when trying to pay in $POW or $PUNKS?"}
                answer={
                  "Your wallet transaction may take several moments to process. Please don't refresh the page during this time. If your transaction receives an error message, it is possible at least one of your items is no longer in stock. If this occurs, your bag will be cleared, and you must return to the shop homepage to re-select from the available inventory. If the item/size is disabled, that means it is no longer available."
                }
              />
              <Accordion
                question={"Why haven't I received a confirmation email?"}
                answer={
                  "If you successfully paid for your item(s) using $POW or $PUNKS but did not complete the credit card payment step for shipping, your order will not be confirmed via email. However, the items are still held for you based on the token transaction, and you will be able to complete check out if you return to the merch store."
                }
              />
              <Accordion
                question={"I have a question about my order, who do I reach out to?"}
                answer={
                  "For the quickest response, please email support@pixelvault.com with your order number, email address used for the order (if different from the one you are emailing from) and as much pertinent information you can share to minimize back and forth and facilitate a resolution. If there are any issues with the merchandise you receive, you must reach out to support@pixelvault.com within 3 days of receipt."
                }
              />
            </div>
          </div>
        </div>
      </main>
      <AlternateFooterSection />
    </>
  );
};

export default MerchInfo;
